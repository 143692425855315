import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"

import "./css/datenschutz.scss"

export default function Datenschutz() {
  return (
    <Layout>
      <div className="datenschutz">
        <h1>Datenschutzerklärung</h1>
        <h2 id="m14">Einleitung</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
          welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von
          uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend
          zusammenfassend bezeichnet als "Onlineangebot“).
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 16. Dezember 2019</p>
        <h2>Inhaltsübersicht</h2>{" "}
        <ul className="index">
          <li>
            <a className="index-link" href="#m14">
              {" "}
              Einleitung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              {" "}
              Verantwortlicher
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              {" "}
              Übersicht der Verarbeitungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m13">
              {" "}
              Maßgebliche Rechtsgrundlagen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              {" "}
              Sicherheitsmaßnahmen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m104">
              {" "}
              Blogs und Publikationsmedien
            </a>
          </li>
          <li>
            <a className="index-link" href="#m182">
              {" "}
              Kontaktaufnahme
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              {" "}
              Bereitstellung des Onlineangebotes und Webhosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m136">
              {" "}
              Präsenzen in sozialen Netzwerken
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              {" "}
              Änderung und Aktualisierung der Datenschutzerklärung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              {" "}
              Begriffsdefinitionen
            </a>
          </li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>{" "}
        <p>
          Mark Brockhoff
          <br />
          Steinsfeldstr. 61
          <br />
          74626 Bretzfeld
        </p>
        <p>
          <strong>E-Mail-Adresse</strong>:{" "}
          <a href="mailto:band@octatonics.de">band@octatonics.de</a>
        </p>
        <p>
          <strong>Impressum</strong>:{" "}
          <a
            href="http://www.octatonics.de/impressum"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.octatonics.de/impressum
          </a>
        </p>
        <ul className="m-elements"></ul>
        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>
            <p>Bestandsdaten (z.B. Namen, Adressen).</p>
          </li>
          <li>
            <p>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</p>
          </li>
          <li>
            <p>Kontaktdaten (z.B. E-Mail, Telefonnummern).</p>
          </li>
          <li>
            <p>
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </p>
          </li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>
            <p>Kommunikationspartner.</p>
          </li>
          <li>
            <p>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</p>
          </li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>
            <p>Content Delivery Network (CDN).</p>
          </li>
          <li>
            <p>Feedback (z.B. Sammeln von Feedback via Online-Formular).</p>
          </li>
          <li>
            <p>Kontaktanfragen und Kommunikation.</p>
          </li>
          <li>
            <p>Remarketing.</p>
          </li>
          <li>
            <p>Sicherheitsmaßnahmen.</p>
          </li>
          <li>
            <p>
              Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung
              von Cookies).
            </p>
          </li>
          <li>
            <p>Vertragliche Leistungen und Service.</p>
          </li>
          <li>
            <p>Verwaltung und Beantwortung von Anfragen.</p>
          </li>
        </ul>
        <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
        <p>
          Im Folgenden teilen wir die Rechtsgrundlagen der
          Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
          personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
          zusätzlich zu den Regelungen der DSGVO die nationalen
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten
          können.
        </p>
        <ul>
          <li>
            <p>
              <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> -
              Die betroffene Person hat ihre Einwilligung in die Verarbeitung
              der sie betreffenden personenbezogenen Daten für einen
              spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
                1 lit. b. DSGVO)
              </strong>{" "}
              - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
              Vertragspartei die betroffene Person ist, oder zur Durchführung
              vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
              betroffenen Person erfolgen.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
              </strong>{" "}
              - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              überwiegen.
            </p>
          </li>
        </ul>
        <p>
          <strong>Nationale Datenschutzregelungen in Deutschland</strong>:
          Zusätzlich zu den Datenschutzregelungen der
          Datenschutz-Grundverordnung gelten nationale Regelungen zum
          Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum
          Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Des Weiteren regelt es die
          Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26
          BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder
          Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von
          Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
          Bundesländer zur Anwendung gelangen.
        </p>
        <ul className="m-elements"></ul> <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen und elektronischen Zugangs zu den Daten als
          auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
          Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
          Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
          die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
          gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
          Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser
          Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
          SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen
          an dem Präfix https:// in der Adresszeile Ihres Browsers.
        </p>
        <ul className="m-elements"></ul>
        <h2 id="m104">Blogs und Publikationsmedien</h2>
        <p>
          Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und
          Publikation (nachfolgend "Publikationsmedium"). Die Daten der Leser
          werden für die Zwecke des Publikationsmediums nur insoweit
          verarbeitet, als es für dessen Darstellung und die Kommunikation
          zwischen Autoren und Lesern oder aus Gründen der Sicherheit
          erforderlich ist. Im Übrigen verweisen wir auf die Informationen zur
          Verarbeitung der Besucher unseres Publikationsmediums im Rahmen dieser
          Datenschutzhinweise.
        </p>
        <ul className="m-elements">
          <li>
            <p>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
              Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen
              und Service, Feedback (z.B. Sammeln von Feedback via
              Online-Formular).
            </p>
          </li>
          <li>
            <p>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <h2 id="m182">Kontaktaufnahme</h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
          Telefon oder via soziale Medien) werden die Angaben der anfragenden
          Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen
          und etwaiger angefragter Maßnahmen erforderlich ist.
        </p>
        <p>
          Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder
          vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
          vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen
          Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an
          der Beantwortung der Anfragen.
        </p>
        <ul className="m-elements">
          <li>
            <p>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
              Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
            </p>
          </li>
          <li>
            <p>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </p>
          </li>
          <li>
            <p>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
              Kommunikation.
            </p>
          </li>
          <li>
            <p>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Um unser Onlineangebot sicher und effizient bereitstellen zu können,
          nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern
          in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern)
          das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
          Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
          Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und
          technische Wartungsleistungen in Anspruch nehmen.
        </p>
        <p>
          Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
          Daten können alle die Nutzer unseres Onlineangebotes betreffenden
          Angaben gehören, die im Rahmen der Nutzung und der Kommunikation
          anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist,
          um die Inhalte von Onlineangeboten an Browser ausliefern zu können,
          und alle innerhalb unseres Onlineangebotes oder von Webseiten
          getätigten Eingaben.
        </p>
        <p>
          <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch
          genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den
          Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden
          die Adressen der Empfänger sowie Absender als auch weitere
          Informationen betreffend den E-Mailversand (z.B. die beteiligten
          Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die
          vorgenannten Daten können ferner zu Zwecken der Erkennung von SPAM
          verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im
          Internet grundsätzlich nicht verschlüsselt versendet werden. Im
          Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber
          (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren
          eingesetzt wird) nicht auf den Servern, von denen sie abgesendet und
          empfangen werden. Wir können daher für den Übertragungsweg der E-Mails
          zwischen dem Absender und dem Empfang auf unserem Server keine
          Verantwortung übernehmen.
        </p>
        <p>
          <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst
          (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den
          Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider gehören.
        </p>
        <p>
          Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
          eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
          (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
          DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
          Stabilität sicherzustellen.
        </p>
        <p>
          <strong>Content-Delivery-Network</strong>: Wir setzen ein
          "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit
          dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
          Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe regional
          verteilter und über das Internet verbundener Server schneller und
          sicherer ausgeliefert werden können.
        </p>
        <ul className="m-elements">
          <li>
            <p>
              <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              <strong>Zwecke der Verarbeitung:</strong> Content Delivery Network
              (CDN).
            </p>
          </li>
          <li>
            <p>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <h2 id="m136">Präsenzen in sozialen Netzwerken</h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke, um mit
          den dort aktiven Nutzern zu kommunizieren oder um dort Informationen
          über uns anzubieten.
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
          Raumes der Europäischen Union verarbeitet werden können. Hierdurch
          können sich für die Nutzer Risiken ergeben, weil so z.B. die
          Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im
          Hinblick auf US-Anbieter, die unter dem Privacy-Shield zertifiziert
          sind oder vergleichbare Garantien eines sicheren Datenschutzniveaus
          bieten, weisen wir darauf hin, dass sie sich damit verpflichten, die
          Datenschutzstandards der EU einzuhalten.
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
          z.B. anhand des Nutzungsverhaltens und sich daraus ergebender
          Interessen der Nutzer Nutzungsprofile erstellt werden. Die
          Nutzungsprofile können wiederum verwendet werden, um z.B.
          Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
          mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
          werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
          in denen das Nutzungsverhalten und die Interessen der Nutzer
          gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
          unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
          (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
          sind und bei diesen eingeloggt sind).
        </p>
        <p>
          Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
          und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
          Netzwerke.
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden können. Nur die Anbieter
          haben jeweils Zugriff auf die Daten der Nutzer und können direkt
          entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
          dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
        </p>
        <ul className="m-elements">
          <li>
            <p>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
              Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
              Kommunikation, Tracking (z.B. interessens-/verhaltensbezogenes
              Profiling, Nutzung von Cookies), Remarketing.
            </p>
          </li>
          <li>
            <p>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <p>
          <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
        </p>
        <ul className="m-elements">
          <li>
            <p>
              <strong>Instagram :</strong> Soziales Netzwerk; Dienstanbieter:
              Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA;
              Website:{" "}
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.instagram.com
              </a>
              ; Datenschutzerklärung:{" "}
              <a
                href="http://instagram.com/about/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://instagram.com/about/legal/privacy
              </a>
              .
            </p>
          </li>
        </ul>
        <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
          können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
        </p>
        <ul className="m-elements"></ul>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
          sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
          gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
          Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
          Begriffe sind alphabetisch sortiert.
        </p>
        <ul className="glossary">
          <li>
            <strong>Content Delivery Network (CDN):</strong> Ein "Content
            Delivery Network" (CDN) ist ein Dienst, mit dessen Hilfe Inhalte
            eines Onlineangebotes, insbesondere große Mediendateien, wie
            Grafiken oder Programm-Skripte mit Hilfe regional verteilter und
            über das Internet verbundener Server, schneller und sicherer
            ausgeliefert werden können.{" "}
          </li>
          <li>
            <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“
            sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person (im Folgenden "betroffene
            Person“) beziehen; als identifizierbar wird eine natürliche Person
            angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
            zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem
            oder mehreren besonderen Merkmalen identifiziert werden kann, die
            Ausdruck der physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identität dieser
            natürlichen Person sind.{" "}
          </li>
          <li>
            <strong>Remarketing:</strong> Vom "Remarketing“ bzw. "Retargeting“
            spricht man, wenn z.B. zu Werbezwecken vermerkt wird, für welche
            Produkte sich ein Nutzer auf einer Webseite interessiert hat, um den
            Nutzer auf anderen Webseiten an diese Produkte, z.B. in
            Werbeanzeigen, zu erinnern.{" "}
          </li>
          <li>
            <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das
            Verhalten von Nutzern über mehrere Onlineangebote hinweg
            nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die
            genutzten Onlineangebote Verhaltens- und Interessensinformationen in
            Cookies oder auf Servern der Anbieter der Trackingtechnologien
            gespeichert (sogenanntes Profiling). Diese Informationen können
            anschließend z.B. eingesetzt werden, um den Nutzern Werbeanzeigen
            anzuzeigen, die voraussichtlich deren Interessen entsprechen.{" "}
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
            natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
            bezeichnet.{" "}
          </li>
          <li>
            <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
            ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
            solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
            Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
            Daten, sei es das Erheben, das Auswerten, das Speichern, das
            Übermitteln oder das Löschen.{" "}
          </li>
        </ul>
        <p className="seal">
          <a
            href="https://datenschutz-generator.de/?l=de"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
            Schwenke
          </a>
        </p>
        <Button className="button" variant="danger">
          <Link to="/">Zur Startseite</Link>
        </Button>
      </div>
    </Layout>
  )
}
